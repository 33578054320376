import React from 'react';

import PROMO_CX from 'mocks/webflow/promo/cx';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/screver-de.webflow.scss';

const PromoCx = () => {
  return (
    <div
      className="webflow webflow-cx-ex"
      dangerouslySetInnerHTML={{ __html: PROMO_CX }}
    ></div>
  );
};

export default PromoCx;
